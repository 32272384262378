import React from 'react';
import toCurrency from 'alisto.js/lib/to-currency';
import lang from 'alisto.js/lib/lang';
import i18n from '../../../../javascript/js/i18n';

const Total = function ({
  subtotal,
  deliveryMethod,
  discountType,
  discount,
  discountPercentage,
  address,
  freightPrice,
  donation,
  serviceFee,
  couvert,
  total,
  paid,
  missingPayment,
  displayMissingPayment,
  change,
  paymentDiscount,
  promotionDiscount,
  nextOrderDiscount,
}) {
  return (
    <table className="table table-borderless table-sm">
      <tbody>
        <tr className="text-muted">
          <td>Subtotal</td>
          <td className="text-right">{toCurrency(subtotal)}</td>
        </tr>
        {freightPrice !== null && (deliveryMethod === 'delivery' ? address : freightPrice > 0) && (
          <tr className="text-muted">
            <td>{i18n.freight}</td>
            <td className="text-right">{toCurrency(freightPrice)}</td>
          </tr>
        )}
        {discountType === 'fixed_value' && parseFloat(discount) > 0 && (
          <tr className="text-muted">
            <td>{i18n.discount}</td>
            <td className="text-right">{toCurrency(-discount)}</td>
          </tr>
        )}
        {discountType === 'percentage' && parseFloat(discountPercentage) > 0 && (
          <tr className="text-muted">
            <td>{i18n.discount}</td>
            <td className="text-right">
              {new Intl.NumberFormat(lang, { style: 'percent' }).format(discountPercentage * 10)}
            </td>
          </tr>
        )}
        {paymentDiscount > 0 && (
          <tr className="text-muted">
            <td>{i18n.paymentDiscount}</td>
            <td className="text-right">{toCurrency(-paymentDiscount)}</td>
          </tr>
        )}
        {promotionDiscount != null && promotionDiscount > 0 && (
          <tr className="text-muted">
            <td>{i18n.promotionDiscount}</td>
            <td className="text-right">{toCurrency(-promotionDiscount)}</td>
          </tr>
        )}
        {nextOrderDiscount != null && nextOrderDiscount > 0 && (
          <tr className="text-muted">
            <td>{i18n.nextOrderDiscount}</td>
            <td className="text-right">{toCurrency(-nextOrderDiscount)}</td>
          </tr>
        )}
        {donation > 0 && (
          <tr className="text-success">
            <td>{i18n.donation}</td>
            <td className="text-right">{toCurrency(donation)}</td>
          </tr>
        )}
        {serviceFee > 0 && (
          <tr className="text-muted">
            <td>{i18n.serviceFee}</td>
            <td className="text-right">{toCurrency(serviceFee)}</td>
          </tr>
        )}
        {couvert > 0 && (
          <tr className="text-muted">
            <td>Couvert</td>
            <td className="text-right">{toCurrency(couvert)}</td>
          </tr>
        )}
        <tr className="font-weight-bold">
          <td>Total</td>
          <td className="text-right">{toCurrency(total)}</td>
        </tr>
        {paid !== null && paid > 0 && (
          <tr className="text-muted pt-4 border-top">
            <td className="pt-3">{i18n.paid}</td>
            <td className="text-right pt-3">{toCurrency(paid)}</td>
          </tr>
        )}
        {displayMissingPayment && missingPayment != null && (
          <tr className="text-muted">
            <td>{i18n.missingPayment}</td>
            <td className="text-right">{toCurrency(missingPayment)}</td>
          </tr>
        )}
        {(missingPayment === null || missingPayment === 0) && change != null && (
          <tr className="text-muted">
            <td>{i18n.change}</td>
            <td className="text-right">{toCurrency(change)}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Total;
