import React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import MultipleOption from './MultipleOption';

const Variation = function ({
  variation, itemVariations, onChange, index, enableNumericInput, outOfStockLabel,
}) {
  if (variation.max === null || variation.max > 0) {
    const itemVariationById = (itemVariation) => itemVariation.id === variation.id;
    const itemVariation = (itemVariations || []).find(itemVariationById) || {};
    const selectedItems = itemVariation.items || [];
    return (
      <div className="mb-3 variation" id={`variation-${variation.id}`} data-index={index}>
        <ListSubheader
          className="px-0 py-2"
          style={{
            lineHeight: 'normal',
            backgroundColor: 'white',
            top: '-10px',
            fontSize: 'inherit',
            color: 'inherit',
          }}
        >
          <strong>{variation.name}</strong>
          <small className="d-block">{variation.hint}</small>
        </ListSubheader>
        <MultipleOption
          selectedItems={selectedItems}
          onChange={onChange}
          id={variation.id}
          min={variation.min}
          max={variation.max}
          items={variation.items}
          index={index}
          enableNumericInput={enableNumericInput}
          outOfStockLabel={outOfStockLabel}
        />
      </div>
    );
  }
  return null;
};

export default Variation;
