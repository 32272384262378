import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const SearchInput = function ({ placeholder, onKeyUp, variant }) {
  const [searchTerm, setSearchTerm] = useState('');

  const adornment = (
    <InputAdornment position="start">
      <i className="fas fa-search mr-2" />
    </InputAdornment>
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onKeyUp(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, onKeyUp]);

  return (
    <FormControl fullWidth>
      <TextField
        id="search-input"
        variant={variant}
        color="primary"
        type="search"
        placeholder={placeholder}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{ startAdornment: adornment }}
      />
    </FormControl>
  );
};

export default React.memo(SearchInput);
