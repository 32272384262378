import React, { useState } from 'react';
import NumericInput from 'react-numeric-input';
import ListItem from '@material-ui/core/ListItem';
import { useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import toCurrency from 'alisto.js/lib/to-currency';
import RadioMultipleOption from './RadioMultipleOption';
import SearchInput from './SearchInput';

const NumericOption = function ({
  items, selectedItems, onChange, min, max, readOnly, itemDescription,
}) {
  const [visibleItems, setVisibleItems] = useState(items);
  const sumByQuantity = (total, selectedItem) => total + (selectedItem.quantity || 0);
  const total = selectedItems.reduce(sumByQuantity, 0);
  const missing = min && total < min;
  const remaining = max ? max - total : 999;
  const theme = useTheme();

  const onSearch = (term) => {
    if (term === '') {
      setVisibleItems(items);
    } else {
      import('alisto.js/lib/search').then((search) => {
        setVisibleItems(search.default(term, items, ['name', 'description']));
      });
    }
  };

  NumericInput.SPEED = 5000;
  NumericInput.DELAY = 5000;

  return (
    <>
      {missing ? <ListItem style={{ height: 0 }}><input required style={{ opacity: 0 }} /></ListItem> : ''}
      {items.length > 5 && (
        <div className="mb-1">
          <SearchInput placeholder="Buscar" onKeyUp={onSearch} />
        </div>
      )}
      {items.map((item) => {
        const selectedItemById = (selectedItem) => selectedItem.id === item.id;
        const selectedItem = selectedItems.find(selectedItemById) || {};
        const optionId = `item-${item.id}`;
        const quantity = selectedItem.quantity || 0;
        const quantityInStock = item.quantity || 9999;
        const maxInStock = Math.min(...[quantity + remaining, quantityInStock]);
        const color = quantity > 0 ? theme.palette.primary.main : null;
        const isVisible = visibleItems.includes(item);
        return (
          <ListItem
            className={`px-0 justify-content-between ${isVisible ? '' : 'd-none'}`}
            key={item.id}
          >
            <div className="my-auto w-100" style={{ color }}>{itemDescription(item, { imagePosition: 'start' })}</div>
            <div>
              <NumericInput
                className="form-control"
                data-id={item.id}
                mobile
                min={0}
                max={maxInStock}
                id={optionId}
                defaultValue={quantity}
                onChange={onChange}
                readOnly={readOnly}
                disabled={!item.inStock}
                style={{
                  input: {
                    border: 'none',
                    width: (quantity > 0 ? '80px' : '0'),
                    background: 'none',
                  },
                  btnUp: { background: 0, border: 0 },
                  plus: { border: '0', backgroundColor: theme.palette.primary.main },
                  btnDown: {
                    background: 0,
                    border: 0,
                    display: (quantity > 0 ? 'inherit' : 'none'),
                  },
                  minus: { border: '0', backgroundColor: theme.palette.primary.main },
                  'input:focus': { boxShadow: 'none' },
                }}
              />
            </div>
          </ListItem>
        );
      })}
    </>
  );
};

const MultipleOption = function ({
  id, min, max, items, selectedItems, onChange, index, enableNumericInput, outOfStockLabel,
}) {
  const itemDescription = (item, { imagePosition }) => (
    <div className="d-flex" style={{ lineHeight: 'normal' }}>
      {imagePosition === 'start' && item.coverImageUrl && (
        <img
          src={item.coverImageUrl}
          loading="lazy"
          alt={item.name}
          style={{ width: 60, height: 60 }}
          className="rounded-lg mr-2"
        />
      )}
      <div className="my-auto">
        <span className={`mr-2 ${item.inStock ? '' : 'text-muted'}`}>
          {item.name}
        </span>
        {parseFloat(item.price) > 0 && (
          <small className="text-muted mr-2">
            (+
            {toCurrency(item.price)}
            )
          </small>
        )}
        {!item.inStock && (
          <Chip label={outOfStockLabel} className="mb-1" size="small" variant="outlined" />
        )}
        {item.description !== '' && (
          <small className="d-block text-muted">
            {item.description}
          </small>
        )}
      </div>
      {imagePosition === 'end' && item.coverImageUrl && (
        <img
          src={item.coverImageUrl}
          loading="lazy"
          alt={item.name}
          style={{ width: 60, height: 60 }}
          className="rounded-lg ml-auto"
        />
      )}
    </div>
  );

  if (max === 1 && min === 1) {
    let selectedItem = selectedItems[0];
    if (selectedItem === undefined && items.length === 1) {
      [selectedItem] = items;
    }
    return (
      <RadioMultipleOption
        id={id}
        items={items}
        selectedItem={selectedItem}
        onChange={onChange}
        itemDescription={itemDescription}
        index={index}
      />
    );
  }
  return (
    <NumericOption
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
      min={min}
      max={max}
      readOnly={!enableNumericInput}
      itemDescription={itemDescription}
    />
  );
};

export default MultipleOption;
