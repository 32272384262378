import React, { useRef, createRef } from 'react';
import { Link } from 'react-scroll';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import isVisible from 'alisto.js/lib/is-visible';
import i18n from '../../../../javascript/js/i18n';

const Navigation = function ({ categories }) {
  const theme = useTheme();
  const useStyles = makeStyles({ root: { color: `${theme.palette.primary.main} !important` } });
  const classes = useStyles();

  const refs = useRef(categories.map(() => createRef())).current;

  const scrollMenuToCategory = (destination, index) => {
    const { current } = refs[index];
    if (current) {
      current.scrollIntoView({ inline: 'center', behavior: 'smooth' });
      history.pushState(null, '', `/#/${i18n.routes.categories}/${destination}`);
    }
  };

  return (
    <div className="mt-2">
      <div className="sticky bg-light" style={{ zIndex: 10 }} data-offset={0}>
        <nav id="categories-menu" className="py-0">
          <ul className="nav nav-tabs flex-nowrap scrollspy">
            {categories.filter(isVisible).map((category, index) => (
              <li className="nav-item" key={category.slug} ref={refs[index]}>
                <Link
                  activeClass={classes.root}
                  className={`nav-link text-nowrap cursor-pointer navigation ${category.slug}`}
                  to={category.slug}
                  spy
                  smooth
                  style={{ flex: '0 0 auto' }}
                  offset={-100}
                  onSetActive={(destination) => scrollMenuToCategory(destination, index)}
                >
                  {category.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
