import React from 'react';
import List from '@material-ui/core/List';
import Variation from './Variation';

const Variations = function ({
  collection, itemVariations, onChange, enableNumericInput, outOfStockLabel,
}) {
  return (
    <List dense>
      {collection.map((variation, index) => (
        <Variation
          key={`variation-${variation.id}`}
          variation={variation}
          itemVariations={itemVariations}
          onChange={onChange}
          index={index}
          enableNumericInput={enableNumericInput}
          outOfStockLabel={outOfStockLabel}
        />
      ))}
    </List>
  );
};

export default Variations;
