import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import SearchInput from './SearchInput';

const RadioOption = function ({ item, itemDescription, checkedId }) {
  const theme = useTheme();
  const useStyles = makeStyles({ primary: { color: `${theme.palette.primary.main} !important` } });
  const classes = useStyles();
  const optionId = `item-${item.id}`;
  const className = checkedId === item.id ? classes.primary : '';
  const control = (
    <Radio
      inputProps={{ 'data-id': item.id }}
      required
      id={optionId}
      value={item.id}
      color="primary"
      disabled={!item.inStock}
      checked={checkedId === item.id}
    />
  );
  return (
    <FormControlLabel
      classes={{
        label: 'flex-grow-1',
      }}
      className={`mx-0 ${className}`}
      control={control}
      label={itemDescription(item, { imagePosition: 'end' })}
    />
  );
};

const RadioMultipleOption = function ({
  id, items, selectedItem, onChange, itemDescription, index,
}) {
  const [visibleItems, setVisibleItems] = useState(items);
  const defaultValue = selectedItem ? selectedItem.id : '';
  const [value, setValue] = useState(defaultValue);

  const onSearch = (term) => {
    if (term === '') {
      setVisibleItems(items);
    } else {
      import('alisto.js/lib/search').then((search) => {
        setVisibleItems(search.default(term, items, ['name', 'description']));
      });
    }
  };

  const handleChange = (event) => {
    setValue(parseInt(event.target.value, 10));
    onChange(event);
    const variation = document.querySelector(`.variation[data-index="${index + 1}"]`);
    if (variation) {
      variation.scrollIntoView({ inline: 'center', behavior: 'smooth' });
    }
  };

  return (
    <>
      {items.length > 5 && (
        <div className="mb-1">
          <SearchInput placeholder="Buscar" onKeyUp={onSearch} />
        </div>
      )}
      <ListItem className="px-0">
        <RadioGroup
          name={`variation[${id}]`}
          value={value}
          onChange={handleChange}
          className="w-100"
        >
          {visibleItems.map((item) => (
            <RadioOption key={`item-${item.id}`} item={item} itemDescription={itemDescription} checkedId={value} />
          ))}
        </RadioGroup>
      </ListItem>
    </>
  );
};

export default RadioMultipleOption;
