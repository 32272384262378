import React, { useEffect, useState } from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import json from 'alisto.js/lib/json';

const NeighborhoodSelect = function ({
  value, id, onChange, includeBlank,
}) {
  const [neighborhoods, setNeighborhoods] = useState([]);

  useEffect(() => {
    json('/internal/neighborhoods').then(setNeighborhoods);
  }, []);

  return neighborhoods.length > 0 ? (
    <NativeSelect
      defaultValue={value}
      input={<OutlinedInput />}
      inputProps={{ id }}
      onChange={(e) => onChange(e.target.value)}
      required
    >
      {includeBlank && (<option>{ }</option>)}
      {neighborhoods.map((neighborhood) => (
        <option key={neighborhood} value={neighborhood}>{neighborhood}</option>
      ))}
    </NativeSelect>
  ) : '';
};

export default NeighborhoodSelect;
