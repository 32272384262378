import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function FixURL() {
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath !== '/' && !window.location.hash.startsWith('#/')) {
      window.history.replaceState(null, '', '/');
      navigate(currentPath, { replace: true });
    }
  }, [navigate]);

  return null;
}

export default FixURL;
