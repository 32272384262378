import React from 'react';
import itemTotal from 'alisto.js/lib/item-total';
import toCurrency from 'alisto.js/lib/to-currency';
import lang from 'alisto.js/lib/lang';
import Typography from '@material-ui/core/Typography';

const findVariation = (product, variationId) => {
  const variationById = (variation) => variation.id === variationId;
  return product.variations && product.variations.find(variationById);
};

const findVariationItem = (variation, itemId) => variation.items.find((item) => item.id === itemId);

const renderVariations = (item) => item.variations.flatMap((variation) => {
  const productVariation = findVariation(item.product, variation.id);
  const totalQuantity = variation.items.reduce((sum, variationItem) => sum + variationItem.quantity, 0);
  return variation.items.map((variationItem) => {
    const total = ['maxing', 'proportional'].includes(variation.reducer) ? `/${totalQuantity}` : '';
    const quantity = `${variationItem.quantity}${total}`;
    const name = variationItem.name || findVariationItem(productVariation, variationItem.id).name;
    return (
      <small className="d-block" key={variationItem.id}>
        {quantity}
        {' '}
        {name}
      </small>
    );
  });
});

const Items = function ({
  items, onEditItem, onRemoveItem, removeLabel,
}) {
  return (
    <table className="table table-sm">
      <tbody>
        {items.map((item, index) => (
          <tr key={item.uuid}>
            <td className="text-break py-3 d-flex">
              {onEditItem && (
                <button
                  onClick={() => onEditItem(index)}
                  className="border-0 p-0"
                  style={{ background: 'none' }}
                  type="button"
                >
                  <Typography color="secondary" variant="inherit">
                    <i className="fas fa-pen mr-2 edit-item" style={{ marginTop: '4px', cursor: 'pointer' }} />
                  </Typography>
                </button>
              )}
              <div>
                <span className="mr-1">
                  {item.quantity.toLocaleString(lang)}
                  x
                </span>
                {item.product.name}
                <div className="text-muted">
                  {item.variations.length > 0 ? renderVariations(item) : ''}
                  <small>{item.observations}</small>
                </div>
              </div>
            </td>
            <td className="text-right py-3">
              {toCurrency(parseFloat(itemTotal(item)))}
              {onRemoveItem && (
                <small className="d-block">
                  <button type="button" onClick={(event) => onRemoveItem(index, event)} className="btn btn-link text-danger m-0 p-0 remove-item text-lowercase">{removeLabel}</button>
                </small>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Items;
