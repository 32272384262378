import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import action from 'alisto.js/lib/action';
import json from 'alisto.js/lib/json';
import store, { key } from './store';
import toCamel from '../../../../javascript/js/to-camel';
import customTheme from '../custom-theme';
import Menu from '../../components/Menu';
import initSource from './source';
import i18n from '../../../../javascript/js/i18n';

const container = document.getElementById('app');
const root = createRoot(container);
const menu = JSON.parse(container.dataset.menu);
const components = toCamel(menu.components);
const { isSignedIn } = container.dataset;

window.addDonation = (donation) => {
  store.dispatch(action('SET_DONATION', donation));
};

const submit = () => {
  import('../populate-order-form').then((populateOrderForm) => {
    populateOrderForm.default(store);
    const state = store.getState();
    document.getElementById('order_delivery_method').value = state.deliveryMethod;
    if (state.table) {
      document.getElementById('order_table_id').value = state.table.id;
    }
    localStorage.removeItem(`${menu.slug}-v2`);
    document.getElementById('new_order').submit();
  });
};

const config = {
  enableItemObservations: menu.enableItemObservations,
  allowPickups: menu.allowPickups,
  enableDelivery: menu.enableDelivery,
  freeFreightLimit: menu.freeFreightLimit,
  serviceTax: menu.serviceTax,
  askForAddressOn: menu.askForAddressOn,
  channel: 'platform',
  addressNeeded: !menu.table && menu.askForAddressOn === 'menu_load',
  userName: document.getElementById('order_user_name').value,
  userMobilePhone: document.getElementById('order_user_mobile_phone').value,
};
if (menu.table) {
  config.table = menu.table;
  config.deliveryMethod = 'served';
  config.enableServiceTax = true;
} else if (!menu.enableDelivery) {
  config.deliveryMethod = 'pickup';
}
store.dispatch(action('MERGE_STATE', config));

initSource(container, store, menu.slug);

const onError = (e) => {
  window.Rollbar.log('Error rendering Menu', e, () => {
    localStorage.removeItem(key);
    window.location.reload();
  });
};

json(container.dataset.categoriesUrl).then((categories) => {
  root.render(
    <ErrorBoundary
      onError={onError}
      fallback={<div>A página irá recarregar.</div>}
    >
      <ThemeProvider theme={customTheme}>
        <Provider store={store}>
          <Menu
            menu={menu}
            dispatch={store.dispatch}
            onSubmit={submit}
            components={components}
            categories={categories}
            isSignedIn={isSignedIn}
          />
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>,
  );

  if (!menu.open && menu.available) {
    window.alert(i18n.onlyScheduledOrdersForNow);
  }
});
